<tech-card class="tech--w-full tech--p-2">
  <tech-card-header class="tech-container__header flex-center">
    <h1 class="tech-container__header__title page-title">
      Oops! 404
    </h1>
  </tech-card-header>
  <tech-card-content>
    <div class="container">
      <div class="container_bg"></div>

      <div class="message_box tech--p-2">
        <h3>
          {{ 'messages.error.lookLikeLost' | translate | sentenceCase }}
        </h3>
        <p>{{ 'messages.error.pageNotAvailable' | translate | sentenceCase }}</p>
      </div>
    </div>
  </tech-card-content>
</tech-card>
