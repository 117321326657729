import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  OnDestroy, ChangeDetectorRef
} from '@angular/core';
import {AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';

// RXJS
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

// Utils
import {hasControlErrors} from "@app/utils";

// Interfaces
import {QuestionTypeEnum} from "@app/mocks/response-mock";

@Component({
  selector: 'app-survey-form',
  templateUrl: './survey-form.component.html',
  styleUrls: ['./survey-form.component.scss']
})
export class SurveyFormComponent implements OnInit, OnDestroy {

  @Output() formDataEvent = new EventEmitter<any>();
  @Output() isValidFormEvent = new EventEmitter<boolean>();

  @Input() isLoading = false;
  @Input() showErrors = false;

  public surveyForm!: UntypedFormGroup;

  private _formData: any;

  private destroyForm$ = new Subject<boolean>();

  constructor(
    private formBuilder: UntypedFormBuilder,
    private changeDetectorRef: ChangeDetectorRef,
  ) {
    this.surveyForm = this.formBuilder.group({});
  }

  public get formControls() {
    return this.surveyForm.controls;
  }

  public get formData(): any {
    return this._formData;
  }

  @Input()
  public set formData(data: any) {
    this._formData = data;
  }

  async ngOnInit(): Promise<void> {
    if (this._formData) {
      this.buildDynamicForm();
    }

    this.initFormSubscriptions();
  }

  ngOnDestroy() {
    this.destroyFormSubscription();
  }

  /*+
  * Method to create scaled answer
   */
  buildScaledAnswer(min: number, max: number) {
    const step = min <= max ? 1 : -1;
    const result: number[] = [];

    for (let i = min; step > 0 ? i <= max : i >= max; i += step) {
      result.push(i);
    }

    return result;
  }

  /**
   * Method to check if a control has errors
   *
   * @param control AbstractControl | null
   */
  public checkFormControlErrors(control: AbstractControl | null) {
    return hasControlErrors(control, this.showErrors);
  }

  /**
   * Build Survey form
   */
  private buildDynamicForm(): void {
    const controlsConfig: { [key: string]: any } = {};

    this._formData?.forEach((question: any) => {
      if (question.questionTypeName === QuestionTypeEnum.MULTIPLE) {
        controlsConfig[question.idQuestion] = [
          null,
          question.isRequired ? Validators.required : null,
        ];

      } else {
        controlsConfig[question.idQuestion] = [
          null,
          question.isRequired ? Validators.required : null,
        ];
      }
    });

    this.surveyForm = this.formBuilder.group(controlsConfig);
    this.changeDetectorRef.detectChanges();
  }

  /**
   * Method to check form checkbox values
   *
   * @param question
   * @param option
   */
  public validateCheckboxValues(question: any, option: any) {
   const checkValueForm = this.surveyForm.controls[question.idQuestion];

    const auxValues = checkValueForm.value;

    if (!auxValues) {
      checkValueForm.setValue([option.idQuestionOption]);
      return;
    } else {
      const filteredValues = auxValues.filter((v: any) => v !== option.idQuestionOption);

      if (filteredValues.length === auxValues.length) {
        filteredValues.push(option.idQuestionOption);
      }

      checkValueForm.setValue(filteredValues);
    }
  }

  /**
   * Method to dispatch event when the form change
   */
  private onFormChanges() {
    this.formDataEvent.emit(this.surveyForm.getRawValue());
    this.isValidFormEvent.emit(this.surveyForm.valid);
  }

  /**
   * Get Form Changes
   *
   * @private
   */
  private initFormSubscriptions(): void {
    this.surveyForm.valueChanges
      .pipe(takeUntil(this.destroyForm$))
      .subscribe(_ => this.onFormChanges());
  }

  /**
   * Destroy Form subscription
   *
   * @private
   */
  private destroyFormSubscription(): void {
    if (!this.destroyForm$?.closed) {
      this.destroyForm$.next(true);
      this.destroyForm$.unsubscribe();
    }
  }

  protected readonly QuestionTypeEnum = QuestionTypeEnum;
  protected readonly String = String;
}
