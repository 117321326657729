import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BrowserModule} from "@angular/platform-browser";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {HttpClientModule} from "@angular/common/http";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";

import {HttpClient, provideHttpClient, withInterceptorsFromDi} from '@angular/common/http';

// Translation library
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';

// Main component
import {AppComponent} from "./app.component";

// Routing
import {AppRoutingModule} from "./app-routing.module";

// Environment
import {environment} from "@environments/environment";

// TECH components
import {TechCardModule} from "@tech/components/tech-card";
import {TechButtonModule} from "@tech/components/tech-button";
import {TechScrollbarModule} from "@tech/components/tech-scrollbar";
import {TechFormFieldModule} from "@tech/components/tech-form-field";
import {TechCheckboxModule} from "@tech/components/tech-checkbox";
import {TechRadioGroupModule} from "@tech/components/tech-radio-group";
import {TechRadioButtonModule} from "@tech/components/tech-radio-button";
import {TechSelectModule} from "@tech/components/tech-select";
import {TechLoaderModule} from "@tech/components/tech-loader";
import {TechIconModule} from "@tech/components/tech-icon";
import {TechCoreModule} from "@tech/components/core";

// Layout components
import {HeaderComponent} from "@app/layouts/header/header.component";
import {FooterComponent} from "@app/layouts/footer/footer.component";

// Pages
import {SurveyComponent} from "@app/pages/survey/survey.component";
import {NotFoundComponent} from "@app/pages/not-found/not-found.component";

// Components
import {SurveyFormComponent} from "@app/forms/survey-form/survey-form.component";
import {MessageComponent} from "@app/components/message/message.component";

// Pipes
import {SentenceCasePipe} from "@app/pipes/sentence-case/sentence-case.pipe";

// API
import {Client} from "@app/core/clients/api-clients";

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    SurveyComponent,
    SurveyFormComponent,
    NotFoundComponent,
    SentenceCasePipe,
    MessageComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forRoot({
      defaultLanguage: 'es',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    AppRoutingModule,
    TechCardModule,
    TechButtonModule,
    TechScrollbarModule,
    TechFormFieldModule,
    TechCheckboxModule,
    TechRadioGroupModule,
    TechRadioButtonModule,
    TechSelectModule,
    TechLoaderModule,
    TechIconModule,
    TechCoreModule
  ],
  providers: [
    provideHttpClient(withInterceptorsFromDi()),
    {
      provide: Client,
      useFactory: () => {
        // Instance of Client
        return new Client(environment.apiUrl);
      },
    },
  ],

/*   providers: [provideHttpClient(withInterceptorsFromDi()),
    {provide: API_BASE_URL, useValue: environment.apiUrl},
  ], */
  bootstrap: [
    AppComponent
  ]
})
export class AppModule {
}

// Loader for Translations
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
