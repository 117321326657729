<div class="custom-container">
  <tech-card class="custom-card-content">
    <tech-card-header>
      <h1>{{ title | translate | sentenceCase }}</h1>
    </tech-card-header>
    <tech-card-content>
      <p class="tech--d-flex tech--gap-0-5 custom-flex-center">
        <tech-icon
          [opticalSize]="20"
          [fill]="true"
          techPrefix
          [style.color]="color">
          {{ icon }}
        </tech-icon>

        {{ message | translate | sentenceCase }}
      </p>
    </tech-card-content>
  </tech-card>
</div>
