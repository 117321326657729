import {Component, OnInit, signal} from '@angular/core';

// Service
import {CallApiSurveyService, IMessageResponse} from "@app/services/call-api-survey/call-api-survey.service";

// API
import {AnswerInputDto, ISubmitSurveyAnswersDto, SubmitSurveyAnswersDto, SurveyDto} from "@app/core/clients/api-clients";

// Enums
import {SurveyStatusEnum} from "@app/enums/survey-status.enum";

@Component({
  selector: 'app-survey',
  templateUrl: './survey.component.html',
  styleUrls: ['./survey.component.scss']
})
export class SurveyComponent implements OnInit {

  public showSurvey = signal(true);
  public isGetLoading = signal(true);
  public isProcessing = signal(false);
  public data: SurveyDto | IMessageResponse = undefined;
  public hasErrors = false;
  public isFormValid = false;

  private formData?: any;

  constructor(
    private callApiSurveyService: CallApiSurveyService,
  ) {
  }

  async ngOnInit() {
    this.isGetLoading.set(true);
    this.callApiSurveyService.getParamsFromUrl();
    this.data = await this.callApiSurveyService.getSurvey();

    if (this.data && !(this.data instanceof SurveyDto)) {
      this.showSurvey.set(false);
    }
    this.isGetLoading.set(false);
  }

  /**
   * Method to get the form data when the event is dispatched
   *
   * @param data any
   */
  public onFormDataChange(data: any): void {
    this.formData = data;
  }

  /**
   * Method to get the form status when the event is dispatched
   *
   * @param isValid boolean
   */
  public onFormValidChange(isValid: boolean): void {
    this.isFormValid = isValid;
  }

  /**
   * Method to cancel and redirect to back route
   */
  public async onSubmit() {
    if (!this.isFormValid) {
      console.log('-- FORM WITH ERRORS --')

      this.hasErrors = true;
      return;
    }

    this.isProcessing.set(true);
    const command: SubmitSurveyAnswersDto = this.formDataMapper(this.formData);
    this.data = await this.callApiSurveyService.postSurvey(command);
    if (this.data) {
      this.showSurvey.set(false);
      this.isProcessing.set(false);
    }
  }

  private formDataMapper(data: any): SubmitSurveyAnswersDto {
    const response: ISubmitSurveyAnswersDto = {
      token: this.callApiSurveyService.token(),
      email: this.callApiSurveyService.email(),
      answers: []
    }

    Object.entries(data).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.forEach(v => {
          const answer: AnswerInputDto = new AnswerInputDto({
            idQuestion: parseInt(key),
            idQuestionOption: v as number
          });
          response.answers.push(answer);
        });
      } else {
        const answer: AnswerInputDto = new AnswerInputDto({
          idQuestion: parseInt(key)
        });

        if (typeof value === 'string') {
          answer.answerText = value;
        } else {
          answer.idQuestionOption = value as number;
        }
        response.answers.push(answer);
      }
    });

    return new SubmitSurveyAnswersDto(response);
  }

  protected readonly StatusEnum = SurveyStatusEnum;
}
