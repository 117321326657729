<form [formGroup]="surveyForm" class="tech-form tech--gap-1">
  @for (question of formData; track question.idQuestion) {
    <div class="tech-form__group tech-form__template--1 tech--mt-2 tech--mb-2">
      @switch (question.questionTypeName) {
        @case (QuestionTypeEnum.SCALE) {
          <p class="tech--m-0">{{ question.statement }}
            @if (question.isRequired) {
              <span class="required-mark">* {{ 'common.required' | translate | sentenceCase }}</span>
            }
          </p>
          <tech-form-field techFormFieldErrorPosition="bottom">
            <tech-radio-group [formControlName]="question.idQuestion">
              <div class="container_radio">
                @for (number of buildScaledAnswer(question.minValue, question.maxValue); track $index) {
                  <tech-radio-button
                    class="item_radio"
                    [value]="String(number)"
                    techRadioButtonColor="primary">
                    {{ number }}
                  </tech-radio-button>
                }
              </div>
            </tech-radio-group>
          </tech-form-field>
        }
        @case (QuestionTypeEnum.MULTIPLE) {
          <p class="tech--m-0">{{ question.statement }}
            @if (question.isRequired) {
              <span class="required-mark">* {{ 'common.required' | translate | sentenceCase }}</span>
            }
          </p>

          <tech-form-field techFormFieldErrorPosition="bottom">
            <div style="display: flex; flex-direction: column;">
              @for (option of question.questionOptions; track option.idQuestionOption) {
                <tech-checkbox
                  [value]="option.optionValue"
                  techCheckboxColor="primary"
                  (change)="validateCheckboxValues(question ,option)">
                  {{ option.optionText }}
                </tech-checkbox>
              }
            </div>
          </tech-form-field>
        }
        @case (QuestionTypeEnum.OPEN) {
          <p class="tech--m-0">
            {{ question.statement }}
            @if (question.isRequired) {
              <span class="required-mark">* {{ 'common.required' | translate | sentenceCase }}</span>
            }
          </p>

          <tech-form-field techFormFieldErrorPosition="bottom">
            <tech-label>{{ 'common.writeAnswer' | translate | sentenceCase}}</tech-label>
            <textarea
              techInput
              techScrollbars
              [formControlName]="question.idQuestion"
              class="tech--w-full tech-resize--vertical">
            </textarea>

            @if (checkFormControlErrors(formControls[question.idQuestion])) {
              <ng-template #techFormError>
                @if (formControls[question.idQuestion].errors?.['required']) {
                  <span>{{ 'messages.error.requiredField' | translate | sentenceCase }}</span>
                }
              </ng-template>
            }
          </tech-form-field>
        }
        @case (QuestionTypeEnum.CLOSE) {
          <p class="tech--m-0">
            {{ question.statement }}
            @if (question.isRequired) {
              <span class="required-mark">* {{ 'common.required' | translate | sentenceCase }}</span>
            }
          </p>

          <tech-select
            [formControlName]="question.idQuestion"
            [placeholder]="('common.selectOne' | translate | sentenceCase )"
            techSelectErrorPosition="bottom">
            @for (option of question.questionOptions; track option.idQuestionOption) {
              <tech-select-item [value]="option.idQuestionOption">
                <tech-select-item-label> {{ option.optionText }}</tech-select-item-label>
              </tech-select-item>
            }
          </tech-select>
        }
        @default {
          <p class="tech--m-0">{{ question.statement }}
            @if (question.isRequired) {
              <span class="required-mark">* {{ 'common.required' | translate | sentenceCase }}</span>
            }
          </p>
          <tech-form-field techFormFieldErrorPosition="bottom">
            <tech-label>{{ 'common.writeAnswer' | translate | sentenceCase}}</tech-label>
            <input
              type="text"
              techInput
              [formControlName]="question.idQuestion">

            @if (checkFormControlErrors(formControls[question.idQuestion])) {
              <ng-template #techFormError>
                <span *ngIf="formControls[question.idQuestion].errors?.['required']">
                  {{ 'messages.error.requiredField' | translate | sentenceCase }}
                </span>
              </ng-template>
            }
          </tech-form-field>
        }
      }
    </div>
  }
</form>
