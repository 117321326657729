import {Injectable, signal} from '@angular/core';

// ROUTER
import {ActivatedRoute} from "@angular/router";

// API
import {Client, SubmitSurveyAnswersDto, SurveyDto} from "@app/core/clients/api-clients";

export interface IMessageResponse {
  title: string,
  message: string,
  icon: string,
  color: string
}
@Injectable({
  providedIn: 'root'
})
export class CallApiSurveyService {

  public token = signal('');
  public email = signal('');

  constructor(
    private route: ActivatedRoute,
    private apiClient: Client,
  ) {}

  public getParamsFromUrl() {
    this.route.queryParams.subscribe(params => {
      this.token.set(params['token']);
      this.email.set(params['email']);
    });
  }

  public async getSurvey(): Promise<SurveyDto | any> {
    try {
      return  await this.apiClient.survey(this.token(), this.email());
    } catch (error) {
      if (error.status !== 200) {
        return this.handleError(error.status);
      }
    }
  }

  public async postSurvey(command: SubmitSurveyAnswersDto) {
    try {
      await this.apiClient.answers(command);
      return this.handleError(409);
    } catch (error) {
      return this.handleError(error.status);
    }
  }

  private handleError(error: number): IMessageResponse {
    switch (error) {
      case 409:
        return {
          title: 'messages.success.surveyAlreadyResponse',
          message: 'messages.success.thanksCollaboration',
          icon: 'check',
          color: 'var(--tech-success)'
        };
      default:
        return {
          title: 'messages.error.unexpectedError',
          message: 'messages.error.notPossibleToShow',
          icon: 'cancel',
          color: 'var(--tech-danger)'
        }
    }
  }
}
