import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

// Pages
import {SurveyComponent} from "@app/pages/survey/survey.component";
import {NotFoundComponent} from "@app/pages/not-found/not-found.component";

const routes: Routes = [
  { path: 'survey', component: SurveyComponent },
  { path: 'not-found', component: NotFoundComponent },
  {
    path: '**', redirectTo: '/not-found', pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
