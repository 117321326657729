@if (isGetLoading()) {
  <div class="tech--d-flex flex-center items-center tech--m-2 tech--p-2">
    <tech-loader [techLoadingSize]="'md'"></tech-loader>
  </div>
} @else if (!isGetLoading() && data && showSurvey()) {
  <tech-card class="tech--w-full tech--p-2">
    <tech-card-header class="tech-container__header">
      <h1 class="tech-container__header__title">
        #{{ data.idSurvey }} - {{ data.title | uppercase }}
      </h1>
    </tech-card-header>
    <tech-card-content>
      <p class="tech-container__header__subtitle">
        {{ data.description }}
      </p>

      @if (data) {
        <app-survey-form
          [isLoading]="true"
          [formData]="data.questions"
          [showErrors]="false"
          (formDataEvent)="onFormDataChange($event)"
          (isValidFormEvent)="onFormValidChange($event)">
        </app-survey-form>
      }

      <div class="tech-container__footer">
        <button
          techButton
          techButtonColor="primary"
          [disabled]="data.status === StatusEnum.inactive || !isFormValid"
          (click)="onSubmit()">
          {{ 'buttons.send' | translate | uppercase }}
        </button>
      </div>
    </tech-card-content>

    <p class="creation-date">{{ 'common.created' | translate | sentenceCase }}: <span>{{ data.creationDate | date:'fullDate' }}</span></p>
  </tech-card>
} @else {
  <app-message
    [title]="data.title"
    [message]="data.message"
    [icon]="data.icon"
    [color]="data.color">
  </app-message>
}
