import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrl: './message.component.scss'
})
export class MessageComponent {

  @Input() title: string;
  @Input() message: string;
  @Input() icon: string;
  @Input() color: string;

}
