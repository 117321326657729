<div class="app" techScrollbars>
  <app-header/>

  <main class="main-container">
    <div class="app-grid rows-content tech--h-full">
      <router-outlet/>
    </div>
  </main>

  <app-footer/>
</div>
